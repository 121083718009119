





















































import { Component, Vue, Prop } from "vue-property-decorator";
import apollo from "@app/plugins/apollo";
import { ProviderVuePage } from "@providers/provider-app";
import { NordpoolProviderData } from "..";
import { GQLLocationFragment } from "@shared/sc-client";

interface NordpoolArea {
  price_code: string;
  area: string;
}
interface InputState {
  disabled: boolean;
  loading: boolean;
  value: any | undefined;
  error: string | undefined;
}
@Component({ components: {} })
export default class NordpoolVue extends Vue {
  @Prop({ default: "view" })
  page?: ProviderVuePage;

  // REACTIVE PROPERTIES
  area!: InputState;
  name!: InputState;
  button!: InputState;
  nordpoolAreas!: NordpoolArea[];
  knownLocations!: GQLLocationFragment[];

  // HOOKS
  data() {
    // data() hook for undefined values
    return {
      area: {
        disabled: false,
        loading: false,
        value: undefined,
        error: undefined
      },
      name: {
        disabled: false,
        loading: false,
        value: undefined,
        error: undefined
      },
      button: { disabled: false, loading: false },
      nordpoolAreas: [],
      knownLocations: []
    };
  }

  async loadAreas() {
    this.area.loading = true;
    // TEMPORARY UGLY FIX
    this.nordpoolAreas = [
      { price_code: "b5291a49-7403-59d1-a4af-61f8565d1ab2", area: "EU.SE1" },
      { price_code: "6f720a38-ed10-5cb2-b3c5-2027698e8793", area: "EU.SE2" },
      { price_code: "502fcd0a-4019-5f77-adba-781444fec4b7", area: "EU.SE3" },
      { price_code: "7548e6d7-257d-551f-a44e-fe99f33bf8ba", area: "EU.SE4" }
    ];
    this.area.loading = false;
  }
  async loadNames() {
    this.name.loading = true;
    this.knownLocations = await apollo.getLocations();
    // Default names
    if (
      this.knownLocations.findIndex(
        f => f.name && f.name.toLowerCase() === "home"
      ) < 0
    ) {
      this.name.value = "home";
    } else if (
      this.knownLocations.findIndex(
        f => f.name && f.name.toLowerCase() === "work"
      ) < 0
    ) {
      this.name.value = "work";
    }
    this.name.loading = false;
  }

  async mounted() {
    await Promise.all([this.loadAreas(), this.loadNames()]);
    this.button.disabled = false;
  }

  // ACTIONS
  async submit() {
    if (typeof this.name.value !== "string" || this.name.value.length < 2) {
      this.name.error = "Minimum of 2 characters";
    } else if (
      this.knownLocations.findIndex(
        f => f.name && f.name.toLowerCase() === this.name.value.toLowerCase()
      ) >= 0
    ) {
      this.name.error = "You already have a location with that name";
    } else {
      this.name.error = undefined;
    }

    if (this.area.value === undefined) {
      this.area.error = "Required";
    } else {
      this.area.error = undefined;
    }

    if (this.name.error || this.area.error) {
      return false;
    } else {
      this.button.loading = true;
      await apollo.providerMutate("nordpool", {
        mutation: "newLocation",
        name: this.name.value,
        latitude: Number(this.$route.query.lat),
        longitude: Number(this.$route.query.long),
        price_code: this.area.value,
        provider_data: { currency: "SEK" } as NordpoolProviderData
      });
      this.button.loading = false;
      this.$router.push("/");
    }
  }
}
