




































































import { Component, Vue } from "vue-property-decorator";
import { gql } from "apollo-boost";
import providers from "@providers/provider-apps";
import { GQLVehicle } from "@shared/sc-schema";

@Component({
  components: {},
  apollo: {
    vehicles: {
      query: gql`
        query GetVehicles {
          vehicles {
            id
            name
            odometer
            batteryLevel
            status
            serviceID
            providerData
          }
        }
      `,
      //update: data => data.vehicles,
      watchLoading(isLoading, _countModifier) {
        this.loading = isLoading;
      },
      pollInterval: 5000,
    },
  },
})
export default class Home extends Vue {
  loading?: boolean;
  vehicles?: GQLVehicle[];

  data() {
    return { loading: false, vehicles: undefined };
  }
  async mounted() {
    // this.loading = true;
    // this.vehicles = await apollo.getVehicles();
    // this.loading = false;
  }

  vehicleDisabled(vehicle: GQLVehicle): string | undefined {
    if (vehicle.providerData.invalid_token || !vehicle.serviceID) {
      return "invalid provider token, please add again";
    }
    if (vehicle.odometer === 0 || vehicle.status === "") {
      return "not polled yet, make sure it is online";
    }
    return undefined;
  }

  vehiclePicture(vehicle: GQLVehicle) {
    /*if (vehicle.providerData && vehicle.providerData.unknown_image) {
      return require("../assets/unknown_vehicle.png");
    } else*/ {
      const provider = providers.find(
        (p) => p.name === vehicle.providerData.provider
      );
      if (provider && provider.image) {
        return provider.image(vehicle, false);
      } else {
        return "";
      }
    }
  }
  selectVehicle(vehicle: GQLVehicle) {
    this.$router.push(`/vehicle/${vehicle.id}`);
  }
}
