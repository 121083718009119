





















































import { strict as assert } from "assert";

import { Component, Vue, Prop } from "vue-property-decorator";
import apollo from "@app/plugins/apollo";
import eventBus, { BusEvent } from "@app/plugins/event-bus";
import { hashID } from "@shared/utils";
import { TeslaNewListEntry } from "./tesla-helper";
import TeslaTokenVue from "./components/tesla-token.vue";
import TeslaNewVehicleList from "./components/tesla-new-list.vue";
import { ProviderVuePage } from "@providers/provider-app";
import provider, {
  TeslaProviderQueries,
  TeslaProviderMutates,
  TeslaToken,
} from "..";

const AUTHORIZE_URL = "https://auth.tesla.com/oauth2/v3/authorize";
const CLIENT_ID = "45618b860d7c-4186-89f4-2374bc1b1b83";
const REDIRECT_URL = `${window.location.origin}/provider/tesla/auth`;
const TESLA_VIRTUAL_KEY_URL = `https://tesla.com/_ak/${window.location.hostname}`;
const SCOPE =
  "openid offline_access vehicle_device_data vehicle_cmds vehicle_charging_cmds";

@Component({
  components: {
    TeslaTokenVue,
    TeslaNewVehicleList,
  },
})
export default class TeslaVue extends Vue {
  @Prop({ default: "view" })
  page?: ProviderVuePage;

  knownVehicleIDs: { [id: string]: string } = {};

  // REACTIVE PROPERTIES
  loading!: boolean;
  showAuthButton!: boolean;
  allProviderVehicles!: TeslaNewListEntry[];
  get newVehiclesNotConnected() {
    return this.allProviderVehicles.filter((f) => f.vehicle_uuid === undefined);
  }
  get newVehiclesConnected() {
    return this.allProviderVehicles.filter((f) => f.vehicle_uuid !== undefined);
  }
  get teslaVirtualKeyUrl() {
    return TESLA_VIRTUAL_KEY_URL;
  }

  // HOOKS
  data() {
    // data() hook for undefined values
    return {
      loading: false,
      showAuthButton: false,
      allProviderVehicles: [],
    };
  }

  async mounted() {
    if (this.page === "auth") {
      this.showAuthButton = true;
      this.loading = true;

      const params = new URLSearchParams(window.location.search);
      const code = params.get("code");
      const state = params.get("state");
      console.debug(`code: ${code}, state: ${state}`);

      if (code === null || state === null || state !== this.authorizeState) {
        console.debug(`Invalid code or state: ${state}`);
        eventBus.$emit(
          BusEvent.AlertWarning,
          "Invalid authorization flow, please try again"
        );
      }

      try {
        const token = await apollo.providerMutate(provider.name, {
          mutation: TeslaProviderMutates.Authorize,
          code,
          callbackURI: REDIRECT_URL,
        });
        // rewrite the URL to remove the code and state and change auth to new
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname.replace("/auth", "/new")
        );
        await this.loadTeslaVehicles(token);
        this.showAuthButton = false;
      } catch (err) {
        console.debug(err);
        eventBus.$emit(
          BusEvent.AlertWarning,
          "Unable to verify Tesla Authorization"
        );
      }
      this.loading = false;
    } else {
      await this.loadTeslaVehicles();
    }
  }

  // ACTIONS
  async loadTeslaVehicles(newProvider?: TeslaToken) {
    this.loading = true;
    this.allProviderVehicles = [];
    // TODO: break this out into a helper function ?
    try {
      for (const v of await apollo.providerQuery(provider.name, {
        query: TeslaProviderQueries.Vehicles,
        token: newProvider,
      })) {
        let entry = this.allProviderVehicles.find((f) => f.vin === v.vin);
        if (!entry) {
          entry = {
            vin: v.vin,
            name: v.display_name,
            vehicle_uuid: v.vehicle_uuid,
            service_uuid: v.service_uuid,
          } as TeslaNewListEntry;
          this.allProviderVehicles.push(entry);
        }
      }
    } catch (err) {
      console.debug(err);
      // No need to catch 401 errors here, the server will already handle it
    }

    this.loading = false;
    if (this.allProviderVehicles.length === 0) {
      this.showAuthButton = true;
    }
  }

  async selectVehicle(vehicle: TeslaNewListEntry) {
    this.loading = true;
    await apollo.providerMutate(provider.name, {
      mutation: TeslaProviderMutates.NewVehicle,
      input: vehicle,
    });
    this.loading = false;
    this.$router.push("/");
  }

  get authorizeState() {
    assert(apollo.account, "No user ID found");
    return hashID(apollo.account.id, `teslaAuthState`);
  }
  async authorize() {
    this.loading = true;
    /*
      Parameters
      Name	Required	Example	Description
      response_type	Yes	code	A string, always use the value "code".
      client_id	Yes	abc-123	Partner application client id.
      redirect_uri	Yes	https://example.com/auth/callback	Partner application callback url, spec: rfc6749.
      scope	Yes	openid offline_access user_data vehicle_device_data vehicle_cmds vehicle_charging_cmds	Space delimited list of scopes, include openid and offline_access to obtain a refresh token.
      state	Yes	db4af3f87...	Random value used for validation.
      nonce	No	7baf90cda...	Random value used for replay prevention.
    */
    const authUrl = `${AUTHORIZE_URL}?client_id=${encodeURIComponent(
      CLIENT_ID
    )}&locale=en-US&prompt=login&redirect_uri=${encodeURIComponent(
      REDIRECT_URL
    )}&response_type=code&scope=${encodeURIComponent(
      SCOPE
    )}&state=${encodeURIComponent(
      this.authorizeState
    )}&nonce=${encodeURIComponent(
      hashID(new Date().toISOString(), Math.random().toString())
    )}`;

    window.location.href = authUrl;
  }
}
