























































































































































import { Component, Vue } from "vue-property-decorator";
import apollo from "@app/plugins/apollo";

@Component({ components: {} })
export default class About extends Vue {
  limit!: number;
  features!: { title: string; icon: string; text: string }[];
  async mounted() {
    const limit = await apollo.getVehicleLimit();
    this.limit = limit === null ? 0 : limit || -1;
  }
  data() {
    return {
      limit: undefined,
      features: [
        {
          title: "AI driven charging",
          icon: "mdi-school-outline",
          text: `Maps usage and charging habits to figure out the needed battery level and charging window.`,
        },
        {
          title: "Off-peak hours and days",
          icon: "mdi-weather-night-partly-cloudy",
          text: `Smart Charge tries to find the best price to charge, whether it is a windy day or weekend with low demand.`,
        },
        {
          title: "Schedule trips",
          icon: "mdi-road",
          text: `Allows you to set a time and needed battery level. It will top-up and turn on climate control just before you leave.`,
        },
        {
          title: "Doctor sleep",
          icon: "mdi-sleep",
          text: `See if your vehicle is sleeping as it should. Vampire drain causes lost range and unnecessary battery usage.`,
        },
        {
          title: "Reminds you to connect",
          icon: "mdi-power-plug-outline",
          text: `Automatically opens your charge port, if a charge is planned (optional feature).`,
        },
        {
          title: "Climate on",
          icon: "mdi-snowflake",
          text: `Turn on your climate control with just a click, without having to wait for the car to wake up.`,
        },
      ],
    };
  }
}
