

















import { Component, Vue, Prop } from "vue-property-decorator";
import { TeslaNewListEntry } from "../tesla-helper";

@Component
export default class TeslaNewVehicleList extends Vue {
  @Prop({ required: true }) readonly list!: TeslaNewListEntry[];
  @Prop({}) readonly subheader?: string;
}
